import { NextPageContext } from 'next';
import parser from 'accept-language-parser';
import requestIp from 'request-ip';
import { parseCookies } from 'nookies';
import {
  Language, languages, Country, countryLanguages,
} from 'core/types';
import {
  isLanguage,
  getLastValue,
  getAsArray,
  getAsCountryArray,
  getAsLanguageArray,
  isCountry,
} from 'core/utils';
import { localizeIP } from 'core/services';
import { defaultInterfaceLanguage } from './config';

function getLocaleCookie(
  ctx: NextPageContext,
  field: string,
): any {
  const cookies = parseCookies(ctx);
  if (cookies[field]) {
    return JSON.parse(cookies[field]);
  }
  return null;
}

function getInitialInterfaceLanguage(
  ctx: NextPageContext = null,
  contentLanguage: Language[] = null,
): Language {
  try {
    const queryInterfaceLanguage = getLastValue(ctx.query.interfaceLanguage);
    if (isLanguage(queryInterfaceLanguage)) {
      return queryInterfaceLanguage;
    }

    const cookieInterfaceLanguage = getLocaleCookie(ctx, 'interfaceLanguage');
    if (isLanguage(cookieInterfaceLanguage)) {
      return cookieInterfaceLanguage;
    }

    if (contentLanguage && contentLanguage.length === 1) {
      return contentLanguage[0];
    }

    let language = parser.pick(languages, ctx.req.headers['accept-language'], { loose: false });
    if (!isLanguage(language)) {
      language = parser.pick(languages, ctx.req.headers['accept-language'], { loose: true });
    }
    if (isLanguage(language)) {
      return language;
    }
  } catch (error) {
    return null;
  }
  return null;
}

async function getInitialCountry(
  ctx: NextPageContext = null,
): Promise<Country[]> {
  try {
    if (ctx.query.country !== undefined) {
      return getAsCountryArray(getAsArray(ctx.query.country));
    }

    const cookieCountry = getLocaleCookie(ctx, 'country');
    if (cookieCountry) {
      return getAsCountryArray(cookieCountry);
    }

    const cloudflareGeo = getLastValue(ctx.req.headers['cf-ipcountry']);
    if (isCountry(cloudflareGeo)) {
      return [cloudflareGeo];
    }

    if (getInitialInterfaceLanguage(ctx) === 'fr') {
      return ['FR'];
    }
  } catch (error) {
    return ['US'];
  }
  return ['US'];
}

function getInitialContentLanguage(
  ctx: NextPageContext = null,
  country: Country[],
): Language[] {
  if (ctx.query.contentLanguage !== undefined) {
    return getAsLanguageArray(getAsArray(ctx.query.contentLanguage));
  }

  const cookieLanguage = getLocaleCookie(ctx, 'contentLanguage');
  if (cookieLanguage) {
    return getAsLanguageArray(cookieLanguage);
  }

  const countryLangs = new Set<Language>();
  for (const c of country) {
    for (const language of countryLanguages[c]) {
      countryLangs.add(language);
    }
  }
  if (countryLangs.size === 1) {
    return Array.from(countryLangs);
  }

  return [];
}

export async function getInitialLocale(
  ctx: NextPageContext,
): Promise<{country: Country[]; contentLanguage: Language[]; interfaceLanguage: Language}> {
  const country = await getInitialCountry(ctx);
  const contentLanguage = getInitialContentLanguage(ctx, country);
  const interfaceLanguage = getInitialInterfaceLanguage(
    ctx, contentLanguage,
  ) || defaultInterfaceLanguage;
  return { country, contentLanguage, interfaceLanguage };
}
