import querystring, { ParsedUrlQueryInput } from 'querystring';
import snakeCaseKeys from 'snakecase-keys';
import { getUsedProperties, isServerSide } from './utils';
import {
  Article,
  ArticlesFetchQuery,
  ArticlesSearchQuery,
  InstagramFeedQuery,
  PropertiesQuery,
  PropertiesResponse,
  TopicsFetchQuery,
  TopicPropertiesResponse,
  TopicPropertiesQuery,
  AutocompleteTopicsQuery,
  GeoIPQuery,
  GeoIPResponse,
} from './types';

export const api = async <T extends unknown>({ url, postParameters = null }): Promise<T> => {
  let urlToQuery = url;
  if (isServerSide() && process.env.NODE_ENV === 'production') {
    urlToQuery = urlToQuery.replace('https://api.knowledia.com/', 'http://api/');
  }
  const response = postParameters ? await fetch(urlToQuery, { method: 'post', body: postParameters }) : await fetch(urlToQuery);
  if (!response.ok) return null;
  return await response.json() as Promise<T>;
};

export const localizeIP = async (
  {
    ip,
  }: GeoIPQuery): Promise<GeoIPResponse> => {
  const parameters = {
    ip,
  } as ParsedUrlQueryInput;
  const url = `${process.env.FRONTEND_API_HOST}/api/geo?${querystring.encode(parameters)}`;
  return api<GeoIPResponse>({ url });
};

export const getTopics = async (
  {
    id, field = '', inversedField = '',
  }: TopicsFetchQuery): Promise<Record<string, any>> => {
  const parameters = snakeCaseKeys({
    id, field, inversedField,
  }) as ParsedUrlQueryInput;
  const url = `${process.env.KNOWLEDIA_API_HOST}/v1/kb/entities?${querystring.encode(parameters)}`;
  return api<Record<string, any>>({ url });
};

export const autocompleteTopics = async (
  {
    query, language = '', count = 20, field = '',
  }: AutocompleteTopicsQuery): Promise<Record<string, any>[]> => {
  const parameters = snakeCaseKeys({
    query, language, count, field,
  }) as ParsedUrlQueryInput;
  const url = `${process.env.KNOWLEDIA_API_HOST}/v1/kb/autocomplete?${querystring.encode(parameters)}`;
  return api<Record<string, any>[]>({ url });
};

export const getProperties = async (
  {
    id, field = '',
  }: PropertiesQuery): Promise<PropertiesResponse> => {
  const parameters = snakeCaseKeys({
    id, field,
  }) as ParsedUrlQueryInput;
  const url = `${process.env.KNOWLEDIA_API_HOST}/v1/kb/properties`;
  return api<PropertiesResponse>({ url, postParameters: JSON.stringify(parameters) });
};

export const getTopicAndProperties = async (
  {
    topicId,
    topicField = null,
    topicInversedField = null,
    propertiesField = null,
  }: TopicPropertiesQuery,
): Promise<TopicPropertiesResponse> => {
  const topics = await getTopics({
    id: topicId,
    field: topicField,
    inversedField: topicInversedField,
  });
  const topic = topics && Object.values(topics).length > 0 ? Object.values(topics)[0] : {};
  const usedProperties = getUsedProperties(topic);
  const properties = usedProperties.length > 0
    ? await getProperties({ id: usedProperties, field: propertiesField }) : {};

  return { topic, properties };
};

export const getArticles = async (
  {
    id, field = '',
  }: ArticlesFetchQuery): Promise<Record<string, Article>> => {
  const parameters = snakeCaseKeys({
    id, field,
  }) as ParsedUrlQueryInput;
  const url = `${process.env.KNOWLEDIA_API_HOST}/v1/articles?${querystring.encode(parameters)}`;
  return api<Record<string, Article>>({ url });
};

export const searchArticles = async <ArticlesSearchResponse>(
  {
    query = '',
    likeDocument = '',
    cursor = '',
    count = 20,
    field = '',
    category = '',
    country = '',
    language = '',
    entities = '',
    significantEntities = 0,
    dateHistogramPeriod = '',
    isFactChecking = false,
    sort = '',
  }: ArticlesSearchQuery): Promise<ArticlesSearchResponse> => {
  const parameters = snakeCaseKeys({
    query,
    likeDocument,
    cursor,
    count,
    field,
    category,
    country,
    language,
    entities,
    significantEntities,
    dateHistogramPeriod,
    isFactChecking,
    sort,
  }) as ParsedUrlQueryInput;
  const url = `${process.env.KNOWLEDIA_API_HOST}/v1/articles/search?${querystring.encode(parameters)}`;
  return api<ArticlesSearchResponse>({ url });
};

export const getInstagramFeed = async <InstagramFeedResponse>(
  {
    username, tag,
  }: InstagramFeedQuery): Promise<InstagramFeedResponse> => {
  const host = 'https://www.instagram.com/';
  const url = tag ? `${host}explore/tags/${tag}` : `${host}${username}`;
  return fetch(url).then((x) => x.text()).then((x) => x.split('window._sharedData = ')[1].split('</script>')[0]).then((x) => JSON.parse(x.substr(0, x.length - 1)))
    .then((x) => x.entry_data.ProfilePage || x.entry_data.TagPage || {})
    .then((x) => x[0].graphql.user || x[0].graphql.hashtag || {})
    .catch(null);
};
